import React from 'react'

import Head from '../components/Head'

const NotFoundPage = () => (
    <>
        <Head>
            <meta http-equiv="refresh" content="0;URL='/'" />
        </Head>
    </>
)

export default NotFoundPage
